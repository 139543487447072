<template>
  <div class="manage">
    <!-- 面包屑 -->

    <div class="all">
      <div class="from_tab">
        <el-tabs
          type="card"
          v-model="activeName"
          stretch
          @tab-click="handleClick"
        >
          <el-tab-pane label="合同模板管理" name="first">
            <div class="btn">
              <span class="left">合同模板</span>
              <p></p>
              <el-upload
                class="upload-demo"
                action="#"
                multiple
                :limit="1"
                :before-upload="handleImageBeforeUpload"
                :on-change="handleChange"
              >
                <el-button class="up_btn right" size="small" type="primary"
                  >上传合同模板</el-button
                >
              </el-upload>
            </div>
            <div class="table-box">
              <el-table
                :data="ListTemplate"
                class="customer-no-border-table"
                :header-cell-style="headerCellStyle"
                :cell-style="cellStyle"
              >
                <el-table-column
                  prop="contractNum"
                  align="center"
                  label="合同编号"
                >
                </el-table-column>
                <el-table-column align="center" label="合同名称" width="200px">
                  <template slot-scope="scope">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="scope.row.contractName"
                      placement="bottom-start"
                      style="width: 200px; white-space: nowrap"
                    >
                      <span class="onespan" style="margin: 0">{{
                        scope.row.contractName
                      }}</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="contractStatus"
                  align="center"
                  label="状态"
                >
                  <template slot-scope="scope">{{
                    scope.row.contractStatus | contractStatus
                  }}</template>
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  align="center"
                  label="提交时间"
                >
                  <template slot-scope="scope">{{
                    scope.row.createTime | formatCreateTime
                  }}</template>
                </el-table-column>
                <el-table-column
                  fixed="right"
                  align="center"
                  width="240"
                  label="操作"
                >
                  <template slot-scope="scope">
                    <el-button
                      class="btns1"
                      @click="handleClickTemplate(scope.row)"
                      type="text"
                      size="small"
                      >查看</el-button
                    >
                    <el-button
                      class="btns2"
                      v-if="scope.row.contractStatus != 2"
                      type="text"
                      size="small"
                      @click="onLineContract(scope.row)"
                      >转为线上合同</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="dto.pageNum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="dto.pageSize"
              layout="sizes,total, prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </el-tab-pane>

          <el-tab-pane label="合同信息" name="second">
            <div class="table-box">
              <el-table
                :data="ListContract"
                class="customer-no-border-table"
                :header-cell-style="headerCellStyle"
                :cell-style="cellStyle"
              >
                <el-table-column
                  prop="contractNum"
                  align="center"
                  label="合同编号"
                >
                </el-table-column>
                <el-table-column align="center" label="合同名称">
                  <template slot-scope="scope">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="scope.row.contractName"
                      placement="bottom-start"
                      style="width: 200px; white-space: nowrap"
                    >
                      <span class="onespan" style="margin: 0">{{
                        scope.row?.contractUrls[0]?.originalName||''
                      }}</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="signatoryName"
                  align="center"
                  label="签订方名称"
                >
                </el-table-column>
                <el-table-column
                  prop="updateTime"
                  align="center"
                  label="签订时间"
                >
                  <template slot-scope="scope">{{
                    scope.row.updateTime | formatCreateTime
                  }}</template>
                </el-table-column>
                <el-table-column fixed="right" align="center" label="操作">
                  <template slot-scope="scope">
                    <el-button
                      class="btns1"
                      @click="handleClickContract(scope.row)"
                      type="text"
                      size="small"
                      >查看</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="dto.pageNum"
              :page-sizes="[10, 20, 30, 50]"
              :page-size="dto.pageSize"
              layout="sizes,total, prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>


    <el-dialog title="合同预览" :visible.sync="isaccount" center>
			<div style="" class="flexItem">
				<div v-for="(item, index) in contimgs" class="ItemClass" :key="index">
					<el-button style="padding:10px" @click="examinecontract(item.storageName)">点击查看文件</br></br></br>{{ item.originalName||'' }}
					</el-button>
				</div>
			</div>
		</el-dialog>
  </div>
</template>

<script>
import { formatDate } from "../../utils/date";
const { baseUrl } = require("@/config");
import {
  getListTemplate,
  getListContract,
  getAddTemplate,
  editTemplate,
  getContractOSSFile,
} from "@/api/userCenters.js";
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  data() {
    return {
      contimgs: [],
      isaccount: false,
      baseUrl: baseUrl,
      activeName: "first",
      ListTemplate: [],
      ListContract: [],
      total: 0,
      dto: {
        pageNum: 1,
        pageSize: 10,
      },
      breadcrumbDto: [
        { path: "/userIndex", title: "个人中心" },
        { path: "/qianzhang", title: "合同管理" },
      ],
    };
  },
  watch: {
    activeName(value) {
      console.log(value);
      this.total = 0;
      this.dto.pageNum = 1;
      this.dto.pageSize = 10;
      if (value == "first") {
        this.getListTemplate();
      } else if (value == "second") {
        this.getListContract();
      }
    },
  },
  methods: {
    //分页查询
    handleSizeChange(val) {
      this.dto.pageNum = 1;
      this.dto.pageSize = val;
      // this.getList();
      if (this.activeName == "first") {
        this.getListTemplate(); //合同模板
      } else {
        this.getListContract(); //合同信息
      }
    },
    //指定页码查询
    handleCurrentChange(val) {
      this.dto.pageNum = val;
      // this.getList();
      if (this.activeName == "first") {
        this.getListTemplate(); //合同模板
      } else {
        this.getListContract(); //合同信息
      }
    },
    handleClick({ label: row }) {
      console.log(row);
      // if (row == "合同模板管理") {
      //   if (!this.beforhandle("personalCenter:contractManage:template"))
      //     return (this.activeName = "");
      // } else if (row == "合同信息") {
      //   if (!this.beforhandle("personalCenter:contractManage:contract"))
      //     return (this.activeName = "");
      // }
    },
    // 点击查看模板列表
    handleClickTemplate(row) {
      if (!this.beforhandle("personalCenter:contractManage:template:detail"))
        return;
      //ie兼容处理
      getContractOSSFile(row.contractUrl, { responseType: "blob" }).then(
        (res) => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(res, row.contractUrl);
          } else {
            // for Non-IE
            let objectUrl = URL.createObjectURL(res);
            let link = document.createElement("a");
            link.href = objectUrl;
            link.setAttribute("download", row.contractUrl);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        }
      );
    },
    examinecontract(row) {
      let url = this.baseUrl + "third/third/ossGetFile/" + row;
      window.open(url);
    },
    // 查看合同信息
    handleClickContract(row) {
      if (!this.beforhandle("personalCenter:contractManage:template:detail"))
        return;
      console.log(row);
      let list = row.contractUrls;
      let newlist = row.annexUrls;
      let mergeArray = list.concat(newlist);
      this.contimgs = mergeArray;
      this.isaccount = true;
      // let url = this.baseUrl + "third/third/ossGetFile/" + row.contractName;
      // window.open(url);
    },
    // 获取合同模板列表
    getListTemplate() {
      const enterpriseId = Vue.ls.get(types.enterpriseId);
      console.log(enterpriseId);
      this.dto.enterpriseId = enterpriseId;

      getListTemplate(this.dto).then((res) => {
        this.ListTemplate = res.data.list;
        this.total = res.data.total;
      });
    },

    // 获取合同信息列表
    getListContract() {
      getListContract(this.dto).then((res) => {
        const enterpriseId = Vue.ls.get(types.enterpriseId);
        // console.log(res);
        // let list = res.data.list
        // let newlist = []
        // for(let i=0;i<list.length;i++){
        //   if(enterpriseId!=list[i].partiesId){
        //      newlist.push(list[i])
        //   }
        // }
        this.ListContract = res.data.list;
        this.total = res.data.total;
        console.log(this.ListContract);
      });
    },
    // 添加合同模板
    handleImageBeforeUpload(file) {
      if (!this.beforhandle("personalCenter:contractManage:template:upload"))
        return;
      let xls = file.name.slice(-3);
      let pdf = file.name.slice(-4);
      let isJPG;
      if (pdf === "docx") {
        isJPG = true;
      } else {
        isJPG = false;
      }
      let otherFiles = file;
      // FormData 对象
      var formData = new FormData();
      // 文件对象
      // let name = file.name.split(".")[0];
      formData.append("file", otherFiles);
      if (isJPG) {
        getAddTemplate(formData).then((res) => {
          if (res.code == 0) {
            // 获取最新列表
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.getListTemplate();
          }
        });
      } else {
        this.$message.error("请上传格式为：docx的合同文件");
      }
    },
    // 上传合同
    handleChange(file, fileList) {
      console.log(file, fileList);
    },
    // 转为线上合同
    onLineContract(row) {
      if (!this.beforhandle("personalCenter:contractManage:template:change"))
        return;
      let config = {
        templateId: row.templateId,
        contractStatus: 1,
      };
      editTemplate(config).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.$confirm("您的合同信息已提交, 请耐心等待", {
            confirmButtonText: "已知悉",
            // type: "warning",
            showCancelButton: false,
            center: true,
          }).then(() => {
            this.getListTemplate();
          });
        }
      });
    },
    // 表头样式
    headerCellStyle() {
      return "background-color:#F7F7F7;color:#333333;font-size:14px;height:45px;text-align:center;font-weight: 400;";
    },
    cellStyle(column) {
      if (column.columnIndex == 0) {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;white-space:nowrap;";
      } else if (column.columnIndex == 1) {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;white-space:nowrap;";
      } else {
        return "text-align:center;border-bottom:1px dashed #E5E5E5;white-space:nowrap;";
      }
    },
  },
  created() {
    // 获取合同相关的数据

    console.log(this.activeName);
    if (this.activeName == "first") {
      this.getListTemplate(); //合同模板
    } else {
      this.getListContract(); //合同信息
    }
    // 上传合同模板
    // this.getAddTemplate();
  },
  filters: {
    // 时间过滤器
    formatCreateTime(time) {
      if (time !== null) {
        let date = new Date(time);
        return formatDate(date, "yyyy-MM-dd hh:mm:ss");
      }
    },
    contractStatus(val) {
      switch (val) {
        case 0:
          return "已上传";
          break;
        case 1:
          return "申请转为线上合同";
          break;
        case 2:
          return "已转为线上合同";
          break;
      }
    },
  },
  components: {
    readcrumb: () => import("../../components/breadcrumb"),
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-pagination .el-select .el-input {
  width: 110px;
}
.table-box {
  min-height: 380px;
  margin-bottom: 20px;
}
.el-breadcrumb__item {
  height: 39px;
  line-height: 39px;
  // padding-top: 10px;
}

::v-deep .el-tabs__content {
  min-height: 580px;
  padding-bottom: 20px;
  // background-color: #4cb2fc;s
}

::v-deep .el-tabs__item {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 800;
  // color: #0294ff;
  line-height: 60px;
  height: 60px;
}

.all {
  // min-height: 750px;
  background: #fff;
  border: 1px solid #e5e5e5;
  margin-bottom: 18px;
  .from_tab {
    width: 100%;
    .btn {
      height: 47px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 12px;
      padding-right: 14px;
      background: #ffffff;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      .left {
        width: 80px;
        height: 17px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 17px;
      }
      .right {
        width: 193px;
        height: 34px;
        background: linear-gradient(90deg, #2079fb, #4cb2fc);
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
        // line-height: 9px;
        text-align: center;
        line-height: 15px;
      }
      // .up_btn {
      //   width: 200px;
      //   height: 50px;
      //   color: black;
      //   font-size: 20px;
      //   border: 1px solid black;
      // }
    }
    .btns1 {
      width: 73px;
      height: 28px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      font-size: 14px;
      // font-family: FZLanTingHei-R-GBK;
      font-weight: 400;
      color: #0a65ff;
    }
    .btns2 {
      width: 134px;
      height: 28px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      font-size: 14px;
      // font-family: FZLanTingHei-R-GBK;
      font-weight: 400;
      color: #0a65ff;
    }
  }
}
.el-button {
  padding: 0;
}
.el-table {
  min-height: 498px;
}
.el-pagination {
  text-align: right;
}
.manage {
  margin-top: 20px;
}
::v-deep .el-table__fixed-right {
  min-height: 498px;
}
::v-deep .el-table__fixed-body-wrapper {
  top: 48px !important;
}
.ItemClass {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
}
.flexItem {
  display: flex;
  justify-content: center;
  flex-wrap:wrap
}
</style>
